.App {
  text-align: center;
  overflow-x: hidden;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-number-container {
  position: relative;
  display: flex;
  align-items: center;
}

.card-number-element {
  flex-grow: 1; /* Ensures that the CardNumberElement takes up most of the container space */
}

.card-brand-logo {
  position: absolute;
  right: 10px; /* Adjust as needed */
  height: 20px; /* Adjust based on your design */
  width: auto;
}